
import  React, { Fragment, useEffect } from 'react';
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';


export default function Compte() {
    const [colis, setColis] = useState([]);
    const [email, setAuth] = useState('');
    const { id_user } = useParams();
    const navigate = useNavigate();
    const socket = io('https://jobs-conseil.tech/laposte/dist1/');


  
    const fetchData = async () => {
      try {
        await getColis();
        const email = localStorage.getItem('email');
        
        if (email === null) {
          navigate('/Connexion');
        }
        setAuth(email);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    useEffect(() => {
        // Écoute de l'événement de mise à jour des colis
        socket.on('updateColis', () => {
          getColis();
        });
      
        fetchData();
      
        return () => {
          // Nettoyage de l'écouteur de l'événement lorsque le composant est démonté
          socket.off('updateColis');
        };
      }, []);
  
    const logOut = () => {
      localStorage.removeItem('email');
      localStorage.getItem('pass');
      localStorage.clear();
      navigate('/Connexion');
    };
  
    const getColis = async () => {
      try {
        const response = await axios.get(`https://jobs-conseil.tech/laposte/dist1/${localStorage.getItem('id_user')}`);
        console.log(response.data);
        setColis(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };
  


    return(

      <Fragment>




<nav className="fix_topscroll logo_on_fixed  topbar navigation">
  <div className="nav-wrapper container">
    <span id="logo-container" className=" brand-logo " >La Poste </span>    
      
      <a href="#"><img style={{height:'40px', marginTop:'5px'}} className="circle" src="./assets/assets/images/DELINAISON4.png" alt="menu user" /></a>
    
    
   

    
  </div>
</nav>
<ul id="slide-nav" className="sidenav sidemenu">
  <li className="user-wrap">
    <div className="user-view row">
     
            <div className="col s3 imgarea">
        <a href="#user" className="status available"><img className="circle" src="./assets/assets/images/menu-user.jpg" alt="menu user" /></a>
      </div>
            <div className="col s9 infoarea">
       
      </div>
    </div>
    
        


  </li>


  <li className="menulinks">
    <ul className="collapsible">
     

</ul>
</li>


     
    <li className="menu-users">
        




  </li>

  </ul>



  <ul id="slide-settings" className="sidenav sidesettings ">
   
  </ul>
<div className="menu-close"><i className="mdi mdi-close"></i></div>

<div className="content-area">

  <div className="pagehead-bg   primary-bg" >
    </div> 






  <div className="spacer"></div>
<div className="spacer"></div>
<div className="spacer"></div>
<div className="spacer"></div>






<div className="container over transparent pr0 pl0">



<div className="section pt0 pb0">
            

            <div className="row ">
                        <div className="col s12 pad-0">  
                        <h5 className="bot-20 sec-tit  text-white" style={{color:'white',}}>Mes identifiants</h5>
                        <div className='card' style={{overflowX:'auto',overflowY:'auto',maxHeight:'350px'}}>
        <div className='card-content'>
        
            <p><font style={{color:'black'}}>EMAIL: </font> <br></br>{localStorage.getItem('email')}</p>
            <p><font style={{color:'black'}}>MOT DE PASSE:</font><br></br>{localStorage.getItem('pass')}</p>
         
        </div>
            

              </div>

            </div>
            </div>
            
          
            
       
            
          
      
                    <div className="spacer"></div>
                    
            
        
            </div>




  </div>









  <div className="row home-mode" style={{background:'orange'}}>
  <div className="spacer"></div>
  <div className="spacer"></div>
  <h5 className="center bot-0 sec-tit primary-text pad-15">La Poste du GABON</h5>
 
  <p className="center-align black-text pad-30">Tel: (+241) 774442290</p>
 
  <p className="center-align black-text pad-30">BP: 20000</p>

  <div className="center">

  
  </div>
  <div className="spacer"></div>
  <div className="spacer"></div>
</div>



<div className="spacer"></div>








<div className="backtotop">
  <a className="btn-floating btn primary-bg">
    <i className="mdi mdi-chevron-up"></i>
  </a>
</div>



</div>

<div className="footer-menu circular">
  <ul>
      <li >
      <Link to='/Expedition'> 
       <i className="mdi mdi-car-pickup"></i>
      <span>Expédit.</span>
      </Link>    </li>
<li>
      <Link to='/Reception'>     <i className="mdi mdi-subdirectory-arrow-right
"></i>
      <span>Récept.</span>
      </Link> 
      </li>
        <li>
        <Link to='/Accueil'>     <i className="mdi mdi-home-outline"></i>
      <span>Accueil</span>
      </Link>     </li>
        <li >
        <a className="active" href="#" >      <i className="mdi mdi-account"></i>
      <span>Compte</span>
      </a>    </li>
        <li >
        <Link to='/Connexion' onClick={logOut} >      <i className="mdi mdi-power"></i>
      <span>Décon.</span>
      </Link>    </li>
      
  </ul>
</div>






</Fragment>

        );

}