
import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';


export default function Login(){
 
    const back1 = {
        backgroundImage: 'url("./assets/assets/images/Image4.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    
      };

      const back2 = {
        backgroundImage: 'url("./assets/assets/images/Image1.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      };

      const back3 = {
        backgroundImage: 'url("./assets/assets/images/Image3.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      };
      const back4 = {
        backgroundImage: 'url("./assets/assets/images/Image2.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      };

    return(

      <Fragment>




<div className="content-area">




  
 

 

<h1 className="white-text center welcome-logo index-welcome">La poste</h1>

<div className="fullfixed index-carousel">
  
  <div className="carousel carousel-fullscreen carousel-slider">

        <a className="carousel-item" href="#carousel-slide-0!">
      <div className="bg" style={back1}></div>
      <div className="item-content center-align white-text">

                  <h3>Accéder à l'interface</h3>
        
      </div>
              <div className="wave-bg">
           <div></div>
        </div>
      
      </a>
        <a className="carousel-item" href="#carousel-slide-1!">
      <div className="bg" style={back2}></div>
      <div className="item-content center-align white-text">

                  <h3>Envoyez vos colis en toutes sécurités</h3>
        
      </div>
              <div className="wave-bg">
           <div></div>
        </div>
      
      </a>
        <a className="carousel-item" href="#carousel-slide-2!">
      <div className="bg"  style={back3}></div>
      <div className="item-content center-align white-text">

                  <h3>Restez informé de la localisation de vos produits en temps réel.</h3>
        
      </div>
              <div className="wave-bg">
          <div></div>
        </div>
      
      </a>
        <a className="carousel-item" href="#carousel-slide-3!">
      <div className="bg" style={back4}></div>
      <div className="item-content center-align white-text">

                  <h3>Récevez vos colis rapidement avec la POSTE GABON</h3>
        
      </div>
              <div className="wave-bg">
          <div></div>
        </div>
      
      </a>
      </div></div>

<div className="center index-start">
    <div style={back3}></div>
  <Link to="/Connexion" className='waves-light btn-large bg-primary'>  Démarrer
  </Link></div>









</div>

</Fragment>

        );

}