
import  React, { Fragment, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';




export default function Reset(){
 
  const marg = {
    marginTop:'-80px',
  };
  const [successMessage, setSuccessMessage] = useState("");
  const REDIRECT_DELAY = 2000; 

  
  let navigate =useNavigate();
  const [user, setUser]=useState({
    email: '',
    password:''

  })
  const handleChange = (e) => {

    setUser(({...user, [e.target.name]: e.target.value}));

}



const submitForm=(e) => {
e.preventDefault();
const sendData = {
    email:user.email,
   

}
console.log(sendData);
axios.post('https://jobs-conseil.tech/laposte/login10/',sendData).then((result)=>{
 if (result.data.Status === '200'){

    setSuccessMessage("Reinitialisation effectuée, veuillez consulter votre boite mail");
    setTimeout(() => {
        navigate('/Connexion');
      }, REDIRECT_DELAY);
 
   
 } else {
    setSuccessMessage("Email inexistant !");
 
 }
}

)

}


    return(

      <Fragment>


<div className="content-area">




<div style={marg} className="container login-area">
  <div className="section">

<div style={{background:'#0000CD'}} className='center bg-primary'>
<a href="#"><img style={{height:'40px', marginTop:'0px'}} className="circle" src="./assets/assets/images/DELINAISON4.png" alt="menu user" /></a>
</div>
    <h3 className="bot-20 center text-info">Reinitialisation de mot de passe</h3>
    <font>Reinitialisez votre mot de passe en cas d'oublie.</font> 
<form onSubmit={submitForm}>

        <div className="row">

        {successMessage && (
                <div className="alert alert-success" style={{color:'#0000CD'}} role="alert">
                  {successMessage}
                </div>

                
              )}
 
    <div className="row">

<div className="spacer"></div>
      <div className="input-field col s10 offset-s1">
        <input name='email' value={user.email} onChange={handleChange} id="email311" type="email" className="validate" />
        <label for="email311">Saisir votre email</label>
      </div>
    

</div>

<div className="spacer"></div>
<div className="col s10 offset-s1 text-black">
<Link to='/Connexion'><font color="#0000CD"><i className="mdi mdi-arrow-left"></i>CONNEXION</font></Link>
</div>
    </div>

    

        
<div className="row center">
  <button className="waves-effect waves-light btn-large bg-primary"><i className="mdi mdi-sync"></i> Reinitialiser</button>
  <div className="spacer"></div>


    <div className="spacer"></div>
  

</div>
</form>


    


  </div>
</div>







</div>



</Fragment>

        );

}