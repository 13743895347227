import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Connexion from './pages/Connexion';
import Accueil from './pages/Accueil';
import Expedition from './pages/Expedition';
import Reception from './pages/Reception';
import Compte from './pages/Compte';
import Login from './pages/Login';
import Reset from './pages/Reset';

function App() {
  return (
    <div className="App">
           <Router>
      <Routes>
        
        <Route path='/' element={<Login />} />
        <Route path='/Connexion' element={<Connexion />} />
         <Route path='/Accueil' element={<Accueil />} />
         <Route path='/Expedition' element={<Expedition />} />
         <Route path='/Reception' element={<Reception />} />
         <Route path='/Compte' element={<Compte />} />
         <Route path='/Reset' element={<Reset />} />
       </Routes>
     </Router>
 
    </div>
  );
}



export default App;
